import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import * as userRoles from '../../constants/userRoles'
import * as appModules from '../../constants/appModules'
import hasPermission from '../../utils/checkPermissions'
import { AccessPermission } from '../../types'
import { logoutCurrentUser } from '../../store/reducers/api/authReducer'
import { resetToast, setToast } from '../../store/reducers/toastReducer'
import { resetUserData } from '../../store/reducers/api/usersReducer'
import { resetProfileError } from '../../store/reducers/api/profileReducer'
import { configureTheme } from '../../utils/configureTheme'

const notionWiki = process.env.REACT_APP_WIKI_LINK

const SideNav = () => {
  const profile = useAppSelector((state) => state.profile.profile)
  const error = useAppSelector((state) => state.profile.error)
  const role = profile?.role
  const companyId = profile?.company?.id
  const userId = profile?.id
  const companyOwnerId = profile?.company?.owner?.id

  const [accessPermissions, setAccessPermissions] = useState<AccessPermission[]>([])
  const [defaultAccessPermissions, setDefaultAccessPermissions] = useState<AccessPermission[]>([])

  const dispatch = useAppDispatch()

  const logOut = () => {
    dispatch(logoutCurrentUser())
  }

  useEffect(() => {
    if (profile?.company) {
      setAccessPermissions(profile.company.accessPermissions)
      setDefaultAccessPermissions(profile.company.defaultAccessPermissions)
      configureTheme(profile.company.theme)
    } else {
      configureTheme(null)
    }
  }, [profile])

  useEffect(() => {
    let timer: any
    if (error && error?.errors) {
      const payload = {
        title: 'Error',
        message: error.errors.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
      // Logout user if token is invalid or has expired
      if (error.errors.message === 'token invalid' || error.errors.message === 'jwt expired') {
        timer = setTimeout(() => {
          dispatch(resetUserData())
          dispatch(resetProfileError())
          dispatch(resetToast())
          dispatch(logoutCurrentUser())
        }, 3000)
      }
    }

    return () => {
      clearTimeout(timer)
    }
  }, [error])

  return (
    <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
      <div className="sb-sidenav-menu">
        <div className="nav mt-4">
          {
            (role === userRoles.ADMIN) &&
            (<NavLink className="nav-link" to="/dashboard">
              <div className="sb-nav-link-icon"><i className="bi bi-clipboard-data"></i></div>
              Dashboard
            </NavLink>)
          }
          <NavLink className="nav-link" to="/shop">
            <div className="sb-nav-link-icon"><i className="bi bi-bag"></i></div>
            Shop
          </NavLink>
          <NavLink className="nav-link" to="/all-orders">
            <div className="sb-nav-link-icon"><i className="bi bi-list-ul"></i></div>
            All Orders
          </NavLink>
          {
            (role === userRoles.ADMIN)
              ? (
                  <NavLink className="nav-link" to="/all-campaigns">
                    <div className="sb-nav-link-icon"><i className="bi bi-truck"></i></div>
                    All Campaigns
                  </NavLink>
                )
              : (hasPermission(appModules.CAMPAIGNS, profile?.role, accessPermissions, defaultAccessPermissions)) &&
              (
                <NavLink className="nav-link" to="/my-campaigns">
                  <div className="sb-nav-link-icon"><i className="bi bi-truck"></i></div>
                  My Campaigns
                </NavLink>
              )
          }
          {
            (role === userRoles.COMPANYADMINISTRATOR || role === userRoles.ADMIN) && (
              <NavLink className="nav-link" to="/invoices">
                <div className="sb-nav-link-icon"><i className="bi bi-receipt"></i></div>
                Invoices
            </NavLink>
            )
          }
          {
            (role === userRoles.ADMIN) && (
              <NavLink className="nav-link" to="/companies">
                <div className="sb-nav-link-icon"><i className="bi bi-building"></i></div>
                All Companies
              </NavLink>
            )
          }
          {
            (role === userRoles.ADMIN) && (
              <NavLink className="nav-link" to="/all-users">
                <div className="sb-nav-link-icon"><i className="bi bi-people"></i></div>
                All Users
              </NavLink>
            )
          }
          {
            (role === userRoles.ADMIN) && (
              <NavLink className="nav-link" to="/email-templates">
                <div className="sb-nav-link-icon"><i className="bi bi-envelope"></i></div>
                Email Templates
              </NavLink>
            )
          }
          {((companyId && userId === companyOwnerId) || (hasPermission(appModules.PRODUCTS, profile?.role, accessPermissions, defaultAccessPermissions))) && <NavLink className="nav-link" to="/inventory">
            <div className="sb-nav-link-icon"><i className="bi bi-archive"></i></div>
            {role === userRoles.ADMIN ? 'Inventory' : 'My Inventory'}
          </NavLink>}
          {
            (role !== userRoles.ADMIN) &&
            ((companyId && userId === companyOwnerId) || (hasPermission(appModules.ADDRESSES, profile?.role, accessPermissions, defaultAccessPermissions))) && <NavLink className="nav-link d-none" to="/my-addresses">
              <div className="sb-nav-link-icon"><i className="bi bi-person-lines-fill"></i></div>
              My Addresses
            </NavLink>
          }
          {
            (role !== userRoles.ADMIN) &&
            ((companyId && userId === companyOwnerId) || (hasPermission(appModules.COMPANIES, profile?.role, accessPermissions, defaultAccessPermissions))) &&
            <NavLink className="nav-link" to={`/my-company/${companyId}`}>
              <div className="sb-nav-link-icon"><i className="bi bi-building"></i></div>
              My Company
            </NavLink>
          }
          {
            (role === userRoles.ADMIN)
              ? (
                  <NavLink className="nav-link" to="/product-categories">
                <div className="sb-nav-link-icon"><i className="bi bi-tags"></i></div>
                Product Categories
                  </NavLink>
                )
              : (hasPermission(appModules.PRODUCTCATEGORIES, profile?.role, accessPermissions, defaultAccessPermissions)) &&
              (
                <NavLink className="nav-link" to="/my-product-categories">
                <div className="sb-nav-link-icon"><i className="bi bi-tags"></i></div>
                  Product Categories
                </NavLink>
              )
          }
          {
            ((companyId && userId === companyOwnerId) || (hasPermission(appModules.ACCESSPERMISSIONS, profile?.role, accessPermissions, defaultAccessPermissions))) && (
              <NavLink className="nav-link" to={'/permissions'}>
                <div className="sb-nav-link-icon"><i className="bi bi-shield-lock"></i></div>
                Access Permissions
              </NavLink>
            )
          }
          {
            (role === userRoles.ADMIN) && (
              <NavLink className="nav-link" to={'/access-controls'}>
                <div className="sb-nav-link-icon"><i className="bi bi-ui-checks-grid"></i></div>
                Access Controls
              </NavLink>
            )
          }
          {/* START NOTE: FEATURE IS STILL IN BETA */}
          {/* {
            (role === userRoles.ADMIN) && <NavLink className="nav-link" to={'/articles'}>
              <div className="sb-nav-link-icon"><i className="fas fa-archive"></i></div>
                Articles
            </NavLink>
          } */}
          {/* END NOTE: FEATURE IS STILL IN BETA */}
          <a
            className="nav-link"
            href={notionWiki}
            target="_blank" rel="noreferrer"
          >
            <div className="sb-nav-link-icon"><i className="bi bi-info-circle"></i></div>
            Wiki
          </a>
        </div>
      </div>
      <div className="sb-sidenav-footer">
        <div className="small">
          <div className="d-grid gap-2">
            <button type="button" className="btn btn-dark" onClick={logOut}>
              <i className="bi bi-power"></i>
              <span className="mx-2">Log Out</span>
            </button>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default SideNav
