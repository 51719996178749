import React, { useEffect, useState } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import dayjs from 'dayjs'
import './css/styles.css'
import AllOrders from './pages/AllOrders'
import NotFound from './pages/Errors/NotFound'
import Auth from './pages/Auth'
import PrivateRoutes from './utils/PrivateRoutes'
import Callback from './pages/Auth/Callback'
import { useAppDispatch, useAppSelector } from './store/hooks'
import { resetAuth, setAuth } from './store/reducers/authReducer'
import Login from './pages/Login'
import Register from './pages/Register'
import Profile from './pages/Profile'
import MyAddresses from './pages/MyAddresses'
import CampaignDetails from './pages/Campaigns/Details'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import Inventory from './pages/Inventory'
import InventoryDetails from './pages/Inventory/Details'
import * as userRoles from './constants/userRoles'
import Companies from './pages/Companies'
import CompanyDetails from './pages/Companies/Details'
import AllCampaigns from './pages/Campaigns'
import AllUsers from './pages/Users'
import CampaignBundles from './pages/AllOrders/CampaignBundles'
import MyCampaigns from './pages/MyCampaigns'
import EmailTemplates from './pages/EmailTemplate'
import Canvas from './pages/Canvas'
import { logEventFirebase } from './config/firebaseConfig'
import { getMyProfile, resetProfileError } from './store/reducers/api/profileReducer'
import { AccessPermission, Module, Permission } from './types'
import hasPermission from './utils/checkPermissions'
import * as appModules from './constants/appModules'
import Permissions from './pages/Permissions'
import Spinner from './components/loaders/Spinner'
import { resetToast, setToast } from './store/reducers/toastReducer'
import { logoutCurrentUser } from './store/reducers/api/authReducer'
import { resetUserData } from './store/reducers/api/usersReducer'
import MaintenanceModes from './pages/MaintenanceModes'
import { getCompanyById, resetCompanyMessage } from './store/reducers/api/companyReducer'
import { READ } from './constants/permissions'
import { configureTheme } from './utils/configureTheme'
import { COMPANY_THEME_SUCCESS_MESSAGE } from './constants/messages'
import { getAllMaintenanceModes } from './store/reducers/api/maintenanceModeReducer'
import Shop from './pages/Shop'
import ShopItemDetails from './pages/Shop/Details'
import AccessControls from './pages/AccessControls'
import ProductAccessControlGroups from './pages/AccessControls/ProductAccessControlGroups'
import ProductAccessControlGroupDetails from './pages/AccessControls/ProductAccessControlGroups/Details'
import CompanyUserGroups from './pages/AccessControls/CompanyUserGroups'
import CompanyUserGroupDetails from './pages/AccessControls/CompanyUserGroups/Details'
import ProductCategories from './pages/ProductCategories'
import ProductCategoryDetails from './pages/ProductCategories/Details'
import Invoices from './pages/Invoices'
import BrandedProducts from './pages/BrandedProducts'
import MyProductCategories from './pages/MyProductCategories'
import Verify from './pages/Auth/Verify'
import PrivacyRules from './pages/PrivacyRules'
import Dashboard from './pages/Dashboard'

function App () {
  const auth = JSON.parse(String(localStorage.getItem('auth')))
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const error = useAppSelector((state) => state.profile.error)
  const message = useAppSelector((state) => state.apiCompany.message)

  const role = profile?.role
  const companyId = currentUser?.company?.id
  const userId = currentUser?.id
  const token = currentUser?.token
  const companyOwnerId = currentUser?.company?.owner?.id
  const [accessPermissions, setAccessPermissions] = useState<AccessPermission[]>([])
  const [defaultAccessPermissions, setDefaultAccessPermissions] = useState<AccessPermission[]>([])

  const dispatch = useAppDispatch()
  const location = useLocation()

  const isOwner = companyOwnerId && userId === companyOwnerId
  const isAllowed = (module: Module, permission: Permission = READ) => {
    return hasPermission(module, role, accessPermissions, defaultAccessPermissions, permission)
  }

  useEffect(() => {
    if (auth?.accessToken) {
      dispatch(setAuth(auth))
    } else {
      dispatch(resetAuth())
    }
  }, [currentUser, auth])

  useEffect(() => {
    logEventFirebase('page_view', { page_path: location.pathname })

    document.querySelectorAll('.modal-backdrop.fade.show').forEach(e => e.remove())
    document.querySelectorAll('.modal-open').forEach(e => e.classList.remove('modal-open'))
    document.body.style.overflow = ''
    document.body.style.paddingRight = ''
  }, [location])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    if (token) {
      dispatch(getMyProfile({ token, signal }))
    }
    if (token && companyId && (isOwner || isAllowed(appModules.COMPANIES))) {
      dispatch(getCompanyById({ id: companyId, token, signal }))
    }
    return () => {
      controller.abort()
    }
  }, [currentUser])

  useEffect(() => {
    if (profile?.company) {
      setAccessPermissions(profile.company.accessPermissions)
      setDefaultAccessPermissions(profile.company.defaultAccessPermissions)
      configureTheme(profile.company.theme)
    } else {
      configureTheme(null)
    }
  }, [profile])

  useEffect(() => {
    let timer: any
    if (error && error?.errors) {
      const payload = {
        title: 'Error',
        message: error.errors.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
      // Logout user if token is invalid or has expired
      if (error.errors.message === 'token invalid' || error.errors.message === 'jwt expired') {
        timer = setTimeout(() => {
          dispatch(resetUserData())
          dispatch(resetProfileError())
          dispatch(resetToast())
          dispatch(logoutCurrentUser())
        }, 3000)
      }
    }

    return () => {
      clearTimeout(timer)
    }
  }, [error])

  useEffect(() => {
    if (token && message === COMPANY_THEME_SUCCESS_MESSAGE) {
      const controller = new AbortController()
      const signal = controller.signal
      dispatch(getMyProfile({ token, signal }))
      dispatch(resetCompanyMessage())
    }
  }, [message])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const page = 1
    const perPage = 5
    if (token) {
      dispatch(getAllMaintenanceModes({ token, perPage, page, signal }))
    }

    return () => {
      controller.abort()
    }
  }, [token])

  return (
    (currentUser && !profile)
      ? (
        <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center"><Spinner/></div>
        )
      : (
        <div className="App">
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route path="/" element={role === userRoles.ADMIN ? <Dashboard /> : <Shop />} />
              <Route path="/all-orders" element={<AllOrders />} />
              <Route path="/all-orders/:outboundId" element={<AllOrders />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/shop" element={<Shop />} />
              <Route path="/shop/:productId" element={<ShopItemDetails />} />
            </Route>
            {/* campaign module */}
            <Route element={<PrivateRoutes redirectPath={role !== userRoles.ADMIN ? location.pathname : undefined} isAllowed={role !== userRoles.ADMIN && (Boolean(companyId && (userId === companyOwnerId)) || hasPermission(appModules.CAMPAIGNS, profile?.role, accessPermissions, defaultAccessPermissions))} />}>
              <Route path="/my-campaigns" element={<MyCampaigns />} />
              <Route path="/my-campaigns/:campaignId" element={<CampaignDetails />} />
              <Route path="/my-campaigns/:campaignId/orders" element={<CampaignBundles />} />
              <Route path="/my-campaigns/:campaignId/orders/:jfsku" element={<CampaignBundles />} />
              <Route path="/my-campaigns/:campaignId/canvas" element={<Canvas />} />
            </Route>
            {/* company module */}
            <Route element={<PrivateRoutes redirectPath={role !== userRoles.ADMIN ? location.pathname : undefined} isAllowed={role !== userRoles.ADMIN && (Boolean(companyId && (userId === companyOwnerId)) || hasPermission(appModules.COMPANIES, profile?.role, accessPermissions, defaultAccessPermissions))} />}>
              <Route path="/my-company/:companyId" element={<CompanyDetails />} />
            </Route>
            {/* address module */}
            <Route element={<PrivateRoutes redirectPath={role !== userRoles.ADMIN ? location.pathname : undefined} isAllowed={role !== userRoles.ADMIN && (Boolean(companyId && (userId === companyOwnerId)) || hasPermission(appModules.ADDRESSES, profile?.role, accessPermissions, defaultAccessPermissions))} />}>
              <Route path="/my-addresses" element={<MyAddresses />} />
            </Route>
            {/* products module */}
            <Route element={<PrivateRoutes redirectPath={location.pathname} isAllowed={Boolean(companyId && (userId === companyOwnerId)) || hasPermission(appModules.PRODUCTS, profile?.role, accessPermissions, defaultAccessPermissions)} />}>
              <Route path="/inventory" element={<Inventory />} />
              <Route path="/inventory/:productId" element={<InventoryDetails />} />
            </Route>
            {/* branded products module */}
            <Route element={<PrivateRoutes redirectPath={location.pathname} isAllowed={Boolean(companyId && (userId === companyOwnerId)) || hasPermission(appModules.PRODUCTCUSTOMISATIONS, profile?.role, accessPermissions, defaultAccessPermissions)} />}>
              <Route path="/branded-products/:id" element={<BrandedProducts />} />
            </Route>
            <Route element = {<PrivateRoutes redirectPath={role !== userRoles.ADMIN ? location.pathname : undefined} isAllowed={(profile?.role === userRoles.ADMIN) || (profile?.role === userRoles.COMPANYADMINISTRATOR)} />}>
              <Route path="/invoices" element={<Invoices />} />
            </Route>
            <Route element = {<PrivateRoutes redirectPath={role !== userRoles.ADMIN ? location.pathname : undefined} isAllowed={role !== userRoles.ADMIN && (Boolean(companyId && (userId === companyOwnerId)) || hasPermission(appModules.PRODUCTCATEGORIES, profile?.role, accessPermissions, defaultAccessPermissions))} />}>
              <Route path="/my-product-categories" element={<MyProductCategories />} />
            </Route>
            <Route element={<PrivateRoutes redirectPath={role === userRoles.ADMIN ? location.pathname : undefined} isAllowed={(profile?.role === userRoles.ADMIN)} />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/companies" element={<Companies />} />
              <Route path="/companies/:companyId" element={<CompanyDetails />} />
              <Route path="/all-campaigns" element={<AllCampaigns />} />
              <Route path="/all-campaigns/:campaignId" element={<CampaignDetails />} />
              <Route path="/all-campaigns/:campaignId/orders" element={<CampaignBundles />} />
              <Route path="/all-campaigns/:campaignId/orders/:jfsku" element={<CampaignBundles />} />
              <Route path="/all-campaigns/:campaignId/canvas" element={<Canvas />} />
              <Route path="/all-users" element={<AllUsers />} />
              <Route path="/email-templates" element={<EmailTemplates />}/>
              <Route path="/maintenance-modes" element={<MaintenanceModes />}/>
              <Route path="/privacy-rules" element={<PrivacyRules />}/>
              <Route element={<AccessControls />}>
                <Route path="/access-controls" element={<ProductAccessControlGroups />} />
                <Route path="/access-controls/product-access-control-groups" element={<ProductAccessControlGroups />} />
                <Route path="/access-controls/product-access-control-groups/:productAccessControlGroupId" element={<ProductAccessControlGroupDetails />} />
                <Route path="/access-controls/company-user-groups" element={<CompanyUserGroups />} />
                <Route path="/access-controls/company-user-groups/:companyUserGroupId" element={<CompanyUserGroupDetails />} />
              </Route>
              <Route path="/product-categories" element={<ProductCategories />} />
              <Route path="/product-categories/:categoryId" element={<ProductCategoryDetails />} />
            </Route>
            {/* access permissions module */}
            <Route element={<PrivateRoutes redirectPath={location.pathname} isAllowed={Boolean(companyId && (userId === companyOwnerId)) || hasPermission(appModules.ACCESSPERMISSIONS, profile?.role, accessPermissions, defaultAccessPermissions)} />}>
              <Route path="/permissions" element={<Permissions />} />
            </Route>

            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/auth/verify" element={<Verify />} />
            <Route path="/auth/callback" element={<Callback />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        )
  )
}

export default App
