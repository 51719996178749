import React from 'react'
import { Formik } from 'formik'
import { bool, object, string } from 'yup'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { editableRoles } from '../../constants/userRoles'
import { privacyRulesModulesObject } from '../../constants/appModules'
import { PrivacyRule } from '../../types'
import { resetPrivacyRuleError } from '../../store/reducers/api/privacyRuleReducer'

type PrivacyRuleEditorProps = { id: string, initialPrivacyRule: Partial<PrivacyRule>, save: Function, isEdit: boolean };

const PrivacyRuleEditor = ({ id, initialPrivacyRule, save, isEdit }: PrivacyRuleEditorProps) => {
  const dispatch = useAppDispatch()
  const isLoading = useAppSelector((state) => state.apiPrivacyRule.isLoading)
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const token = currentUser?.token

  const privacyRuleSchema = object({
    role: string().required('Role is required').oneOf(editableRoles.map(role => role.value)),
    module: string().required('Module is required'),
    isEnabled: bool().required('Is Enabled is required')
  })

  const savePrivacyRule = (id: string, privacyRule: Partial<PrivacyRule>) => {
    const controller = new AbortController()
    const signal = controller.signal
    if (token) {
      dispatch(save({ token, privacyId: id, privacyRule, signal }))
    }
  }

  return (
    <Formik
      validationSchema={privacyRuleSchema}
      enableReinitialize
      initialValues={{ ...initialPrivacyRule }}
      onSubmit={(values, actions) => {
        savePrivacyRule(id, values)
        actions.setSubmitting(false)
        dispatch(resetPrivacyRuleError())
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="role" className="form-label">User Role</label>
            <select
              id="role"
              name="role"
              value={values.role}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`form-select ${touched.role && errors.role ? 'is-invalid' : ''}`}
            >
              <option value="">Select Role</option>
              {editableRoles.map((role) => (
                <option key={role.value} value={role.value}>{role.text}</option>
              ))}
            </select>
            <div className="invalid-feedback">{errors.role}</div>
          </div>

          <div className="mb-3">
            <label htmlFor="module" className="form-label">Module</label>
            <select
              id="module"
              name="module"
              value={values.module}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`form-select ${touched.module && errors.module ? 'is-invalid' : ''}`}
            >
              <option value="">Select Module</option>
              {Object.entries(privacyRulesModulesObject).map(([key, value]) => (
                <option key={key} value={key}>{value}</option>
              ))}
            </select>
            <div className="invalid-feedback">{errors.module}</div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <p className="mb-1">Is privacy rule enabled?</p>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="isEnabled"
                    id="isEnabledYes"
                    onChange={() => setFieldValue('isEnabled', true)}
                    value="true"
                    checked={values.isEnabled === true}
                  />
                  <label className="form-check-label" htmlFor="isEnabledYes">
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="isEnabled"
                    id="isEnabledNo"
                    onChange={() => setFieldValue('isEnabled', false)}
                    value="false"
                    checked={values.isEnabled === false}
                  />
                  <label className="form-check-label" htmlFor="isEnabledNo">
                    No
                  </label>
                </div>
                <div className="invalid-feedback">{errors.isEnabled}</div>
              </div>
            </div>
          </div>

          <div className="text-end">
            <button type="submit" className="btn btn-primary" disabled={isSubmitting || isLoading}>
              Save
            </button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default PrivacyRuleEditor
