export const ACCESSPERMISSIONS = 'accessPermissions'
export const ADDRESSES = 'addresses'
export const BUNDLES = 'bundles'
export const CAMPAIGNS = 'campaigns'
export const COMPANIES = 'companies'
export const COSTCENTERS = 'costCenters'
export const LEGALTEXTS = 'legalTexts'
export const ORDERS = 'orders'
export const PICTURES = 'pictures'
export const PRIVACYRULES = 'privacyRules'
export const PRODUCTS = 'products'
export const RECIPIENTS = 'recipients'
export const SALUTATIONS = 'salutations'
export const SECONDARYDOMAINS = 'secondaryDomains'
export const SHIPMENTS = 'shipments'
export const USERS = 'users'
export const COMPANYSUBSCRIPTIONS = 'companySubscriptions'
export const CAMPAIGNADDITIONALPRODUCTSETTINGS = 'campaignAdditionalProductSettings'
export const PRODUCTCATEGORIES = 'productCategories'
export const PRODUCTCUSTOMISATIONS = 'productCustomisations'
export const PRODUCTSTOCKNOTIFICATIONS = 'productStockNotifications'

export const modulesObject = {
  [ACCESSPERMISSIONS]: 'Access Permissions',
  [ADDRESSES]: 'Addresses',
  [BUNDLES]: 'Bundles',
  [CAMPAIGNS]: 'Campaigns',
  [COMPANIES]: 'Companies',
  [COSTCENTERS]: 'Cost Centers',
  [LEGALTEXTS]: 'Legal Texts',
  [ORDERS]: 'Orders',
  [PICTURES]: 'Pictures',
  [PRIVACYRULES]: 'Privacy Rules',
  [PRODUCTS]: 'Products',
  [RECIPIENTS]: 'Recipients',
  [SALUTATIONS]: 'Salutations',
  [SECONDARYDOMAINS]: 'Secondary Domains',
  [SHIPMENTS]: 'Shipments',
  [USERS]: 'Users',
  [COMPANYSUBSCRIPTIONS]: 'Company Subscriptions',
  [CAMPAIGNADDITIONALPRODUCTSETTINGS]: 'Campaign Additional Product Settings',
  [PRODUCTCATEGORIES]: 'Product Categories',
  [PRODUCTSTOCKNOTIFICATIONS]: 'Product Stock Notifications',
  [PRODUCTCUSTOMISATIONS]: 'Product Customisations',
  '': ''
}

export const privacyRulesModulesObject = {
  [ADDRESSES]: 'Addresses',
  [ORDERS]: 'Orders',
  [RECIPIENTS]: 'Recipients',
  [USERS]: 'Users'
}
