import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Placeholder from '../../assets/images/placeholder.png'

type DropZopProps = {
  handleUpload: (input: File) => void
  validWidth?: number
  validHeight?: number
  className?: React.CSSProperties
  uploadProgress: number
  uploadError: string | null
  message?: string
  id?: string
  hasError?: boolean
  imageUrl?: string | null
  isEdit?: boolean
  showClearButton?: boolean
}

const DropZone = ({
  handleUpload,
  uploadProgress,
  uploadError,
  message,
  id,
  hasError = false,
  imageUrl = null,
  isEdit = false,
  showClearButton = false
}: DropZopProps) => {
  const [previewUrl, setPreviewUrl] = useState<string | null>(imageUrl)

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0]
      handleUpload(file)

      const reader = new FileReader()
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string)
      }
      reader.readAsDataURL(file)
    },
    [handleUpload]
  )

  useEffect(() => {
    if (isEdit) {
      setPreviewUrl(imageUrl)
    }
  }, [imageUrl, isEdit])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    accept: { 'image/png': ['.png'], 'image/jpeg': ['.jpeg'] },
    onDrop
  })

  return (
    <div
      className={`w-100 d-flex border rounded text-center ${
        (uploadError || hasError) ? 'border-danger' : isDragActive ? 'border-primary' : ''
      }`}
    >
      <div className="container-fluid gx-0 cursor-pointer">
        <div {...getRootProps({ className: 'dropzone' })} className="pt-3">
          <input {...getInputProps()} id={id} />
          <i className="bi bi-file-earmark-image h2"></i>
          <p className="small lh-1 px-2">
            Drop your image here or click to{' '}
            <span className="text-primary fw-bold">browse</span>
          </p>
          <div className="my-3 position-relative d-inline-block">
            <img
              src={previewUrl || Placeholder}
              alt="Image Preview"
              className="img-fluid border rounded"
              style={{ maxHeight: '200px' }}
              onError={(e) => {
                const target = e.target as HTMLImageElement
                target.onerror = null
                target.src = Placeholder
              }}
            />
            {(showClearButton && previewUrl) && (
              <button
                type="button"
                className="btn btn-danger btn-sm rounded-circle position-absolute top-0 end-0 m-2"
                onClick={(e) => {
                  e.stopPropagation()
                  setPreviewUrl(null)
                }}
              >
                <i className="bi bi-x-lg"></i>
              </button>
            )}
          </div>
          <div>
            <p className={`px-2 small ${uploadError ? 'text-danger' : ''}`}>
              {uploadError ||
                (message ??
                  'Recommended image should be a square image | Below 5MB | JPEG | PNG')}
            </p>
            <div
              className="progress"
              style={{
                height: '0.2em'
              }}
            >
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${uploadProgress.toFixed(0)}%` }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DropZone
