import React, { ChangeEvent, useEffect, useState } from 'react'
import { READ, READWRITE } from '../../constants/permissions'
import dayjs from 'dayjs'
import { Formik } from 'formik'
import { object, string } from 'yup'
import Progress from '../../components/loaders/Progress'
import SkeletonTableRow from '../../components/loaders/skeleton/SkeletonTableRow'
import { PencilIcon } from '../../components/icons/PencilIcon'
import { TrashIcon } from '../../components/icons/TrashIcon'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { AccessPermission, Permission, ProductCategory } from '../../types'
import Pagination from '../../components/Pagination'
import * as userRoles from '../../constants/userRoles'
import {
  deleteProductCategoryById,
  editProductCategoryById,
  resetProductCategoryError,
  resetProductCategoryMessage
} from '../../store/reducers/api/productCategoryReducer'
import ProductCategoryEditor from '../../components/ProductCategories/ProductCategoryEditor'
import {
  PRODUCT_CATEGORY_CREATION_SUCCESS_MESSAGE,
  PRODUCT_CATEGORY_DELETION_MESSAGE,
  PRODUCT_CATEGORY_SORT_ORDER_UPDATE_SUCCESS_MESSAGE,
  PRODUCT_CATEGORY_UPDATE_SUCCESS_MESSAGE
} from '../../constants/messages'
import { setToast } from '../../store/reducers/toastReducer'
import { dismissModal } from '../../utils/dismissModal'
import useDebounce from '../../utils/hooks/useDebounce'
import ImageCropper from '../../components/ImageCropper/ImageCropper'
import { getDownloadURLFirebase, getMetadataFirebase, refFirebase, storageFirebase, uploadBytesResumableFirebase } from '../../config/firebaseConfig'
import { openModal } from '../../utils/openModal'
import { addCompanyProductCategory, getCompanyProductCategories, resetCompanyMessage, updateCompanyProductCategoriesSortOrder } from '../../store/reducers/api/companyReducer'
import hasPermission from '../../utils/checkPermissions'
import * as appModules from '../../constants/appModules'

const firebaseStorageEnvironment = process.env.REACT_APP_FIREBASE_STORAGE_ENVIRONMENT || 'develop'

interface SetImageProps {
  url: string;
  filename: string;
  size: number;
  mimeType: string;
}
const MyProductCategories = () => {
  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const profile = useAppSelector((state) => state.profile.profile)
  const isLoadingProductCategory = useAppSelector((state) => state.apiProductCategory.isLoading)
  const messageProductCategory = useAppSelector((state) => state.apiProductCategory.message)
  const errorProductCategory = useAppSelector((state) => state.apiProductCategory.error)
  const isLoading = useAppSelector((state) => state.apiCompany.isLoadingProductCategories)
  const isLoadingCompanyProductCategorySortOrder = useAppSelector((state) => state.apiCompany.isLoadingCompanyProductCategorySortOrder)
  const message = useAppSelector((state) => state.apiCompany.message)
  const productCategories = useAppSelector((state) => state.apiCompany.productCategories)
  const metadata = useAppSelector((state) => state.apiCompany.productCategoriesMetadata)
  const error = useAppSelector((state) => state.apiCompany.error)
  const [isRefreshing, setIsRefreshing] = useState(false)

  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [searchTermProductCategories, setSearchTermProductCategories] = useState<string>('')
  const debouncedSearchTermProductCategories: string = useDebounce<string>(searchTermProductCategories, 800)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [uploadError, setUploadError] = useState<string | null>(null)
  const [image, setImage] = useState<SetImageProps | null>(null)
  const [imageBuffer, setImageBuffer] = useState<string>('')
  const [croppedImageBuffer, setCroppedImageBuffer] = useState<File |null>(null)
  const [isProductCategoryEditMode, setIsProductCategoryEditMode] = useState<boolean>(false)
  const [isImageUploading, setIsImageUploading] = useState(false)
  const [initialProductCategory, setInitialProductCategory] = useState<Partial<ProductCategory>>({
    name: '',
    description: '',
    sortIndex: 0,
    isHidden: false
  })
  const [localProductCategories, setLocalProductCategories] = useState<ProductCategory[]>([])
  const [accessPermissions, setAccessPermissions] = useState<AccessPermission[]>([])
  const [defaultAccessPermissions, setDefaultAccessPermissions] = useState<AccessPermission[]>([])

  const token = currentUser?.token
  const companyId = profile?.company?.id
  const role = profile?.role || userRoles.USER
  const isAllowed = (permission: Permission = READ) => {
    return hasPermission(appModules.PRODUCTCATEGORIES, profile?.role, accessPermissions, defaultAccessPermissions, permission)
  }
  const dispatch = useAppDispatch()

  const handleShowEntries = (event: ChangeEvent<HTMLSelectElement>) => {
    setPage(1)
    setPerPage(Number(event.target.value))
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handleProductCategoryRefresh = () => {
    const controller = new AbortController()
    const signal = controller.signal
    if (token && isAllowed() && companyId) {
      dispatch(getCompanyProductCategories({ token, perPage, page, signal, search: debouncedSearchTermProductCategories, companyId }))
    }
  }

  const searchSchema = object({
    search: string()
      .max(24, 'Search Name is too long')
  })

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal

    if (token && isAllowed() && companyId) {
      dispatch(getCompanyProductCategories({ token, perPage, page, signal, search: debouncedSearchTermProductCategories, companyId }))
    }

    return () => {
      controller.abort()
    }
  }, [perPage, page, debouncedSearchTermProductCategories, profile, accessPermissions, defaultAccessPermissions])

  useEffect(() => {
    if (profile?.company) {
      setAccessPermissions(profile.company.accessPermissions)
      setDefaultAccessPermissions(profile.company.defaultAccessPermissions)
    }
  }, [profile])

  useEffect(() => {
    setLocalProductCategories(productCategories)
  }, [productCategories])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const allowedMessages = [
      PRODUCT_CATEGORY_CREATION_SUCCESS_MESSAGE,
      PRODUCT_CATEGORY_UPDATE_SUCCESS_MESSAGE,
      PRODUCT_CATEGORY_DELETION_MESSAGE,
      PRODUCT_CATEGORY_SORT_ORDER_UPDATE_SUCCESS_MESSAGE
    ]
    if (message && allowedMessages.includes(message)) {
      const payload = {
        title: 'Success',
        message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dismissModal('productCategoryModal')
      dismissModal('productCategoryEditModal')
      setImage(null)
      if (token && isAllowed() && companyId) {
        if (message === PRODUCT_CATEGORY_SORT_ORDER_UPDATE_SUCCESS_MESSAGE) {
          setIsRefreshing(true)
        }
        dispatch(getCompanyProductCategories({ token, perPage, page, signal, companyId })).then(() => {
          setIsRefreshing(false)
        })
        dispatch(resetCompanyMessage())
      }
    }
  }, [message])

  useEffect(() => {
    const controller = new AbortController()
    const signal = controller.signal
    const allowedMessages = [
      PRODUCT_CATEGORY_UPDATE_SUCCESS_MESSAGE,
      PRODUCT_CATEGORY_DELETION_MESSAGE
    ]
    if (messageProductCategory && allowedMessages.includes(messageProductCategory)) {
      const payload = {
        title: 'Success',
        message: messageProductCategory,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'success'
      }
      dispatch(setToast(payload))
      dismissModal('productCategoryModal')
      dismissModal('productCategoryEditModal')
      setImage(null)
      if (token && isAllowed() && companyId) {
        dispatch(getCompanyProductCategories({ token, perPage, page, signal, companyId }))
        dispatch(resetProductCategoryMessage())
      }
    }
  }, [messageProductCategory])

  useEffect(() => {
    if (error && error?.errors) {
      const payload = {
        title: 'Error',
        message: error?.errors.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
      dispatch(resetProductCategoryError())
    }
    if (errorProductCategory && errorProductCategory?.errors) {
      const payload = {
        title: 'Error',
        message: errorProductCategory?.errors.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }
      dispatch(setToast(payload))
    }
  }, [error, errorProductCategory])

  const openCropModal = (file: File) => {
    const url = URL.createObjectURL(new Blob([file], { type: file.type }))
    setImageBuffer(url)
    openModal('cropImage')
    dismissModal('productCategoryModal')
    dismissModal('productCategoryEditModal')
  }

  const handleFileUpload = (file: File) => {
    uploadCategoryImage(file)
  }
  const handleCroppedImage = (file: File) => {
    setCroppedImageBuffer(file)
  }
  const fileNameGenerator = (filename: string) => {
    const ext = filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2)
    return `${Date.now()}.${ext}`
  }

  const uploadCategoryImage = async (file: File) => {
    try {
      setIsImageUploading(true)
      const fileSizeBytes = 5 * 1024 * 1024

      if (file && file.size > fileSizeBytes) {
        setUploadError('Select an image that is less than 5MB')
        return
      } else {
        setUploadError(null)
      }

      if (file && file.type.startsWith('image/')) {
        const imageFileName = fileNameGenerator(file?.name)

        const storageRefPath = `${firebaseStorageEnvironment}/companies/images/${imageFileName}`

        const storageRef = refFirebase(storageFirebase, storageRefPath)

        const uploadTask = uploadBytesResumableFirebase(storageRef, file)

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            setUploadProgress(percentage)
          },
          (error) => {
            setUploadError(error.message)
            setUploadProgress(0)
            setUploadProgress(0)
          },
          () => {
            getDownloadURLFirebase(uploadTask.snapshot.ref).then((url) => {
              getMetadataFirebase(storageRef).then((metadata) => {
                const image = {
                  url,
                  filename: metadata.name,
                  size: metadata.size,
                  mimeType: metadata.contentType || file.type
                }
                setImage(image)
                setUploadError(null)
                setUploadProgress(0)
                setIsImageUploading(false)
              })
            })
          }
        )
      }
      dismissModal('cropImage')
      if (isProductCategoryEditMode) {
        openModal('productCategoryEditModal')
      } else {
        openModal('productCategoryModal')
      }
    } catch (error: any) {
      setUploadError(error.message)
    }
  }

  return (
    <main className="container-fluid px-4 py-4">
      <div className="card">
        <div className="m-4">
          <div className="navbar navbar-expand mb-3">
            <p className="h5"><i className="bi bi-tags me-1"></i>Product Categories</p>
            <ul className="navbar-nav ms-auto me-0 me-md-0 my-0 my-md-0">
              <div className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-2 my-2 my-md-0">
                <Formik
                  validationSchema={searchSchema}
                  enableReinitialize
                  initialValues={{
                    search: ''
                  }}
                  onSubmit={({ search }, actions) => {
                    setSearchTermProductCategories(search)
                    if (page !== 1) {
                      setPage(1)
                    }

                    actions.setSubmitting(false)
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="input-group">
                        <input
                          onChange={(event) => {
                            const search = event.target.value
                            handleChange(event)
                            setSearchTermProductCategories(search)
                            if (page !== 1) {
                              setPage(1)
                            }
                          }}
                          maxLength={24}
                          onBlur={handleBlur}
                          value={values.search}
                          className={`form-control ${
                            errors.search && touched.search && errors.search
                              ? 'is-invalid'
                              : ''
                          }`}
                          type="text"
                          placeholder="Search..."
                          aria-label="Search"
                          aria-describedby="btnNavbarSearch"
                          name="search"
                        />
                        <button
                          className="btn btn-outline-dark"
                          id="btnNavbarSearch"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
              <button
                type="button"
                className="btn btn-outline-primary btn-sm text-nowrap"
                data-bs-toggle="modal"
                data-bs-target="#productCategoryModal"
                title="Add Product Category"
                aria-label="Add Product Category"
                onClick={() => {
                  setInitialProductCategory({
                    name: '',
                    description: '',
                    sortIndex: 0,
                    isHidden: false

                  })
                  setIsProductCategoryEditMode(false)
                }}
              >
                <i className="bi bi-plus-circle"></i>
                <span className="d-none d-md-inline-block ms-1">Add Category</span>
              </button>
              <button
                type="button"
                title="Refresh"
                aria-label="Refresh"
                className="btn btn-outline-dark ms-2"
                onClick={() => handleProductCategoryRefresh()}
              >
                <i className="fas fa-redo"></i>
              </button>
            </ul>
          </div>
            {(isLoading || isLoadingCompanyProductCategorySortOrder) ? <Progress /> : <hr className="border border-primary border-1 opacity-50"></hr>}
          <div className="table-responsive">
            <table className="table table-hover table-centered table-nowrap align-middle">
              <thead>
                <tr>
                  <th scope="col" className="w-20">ID</th>
                  <th scope="col" className="w-25">Name</th>
                  <th scope="col" className="w-20 text-center">Visibility</th>
                  <th scope='col' className='w-35'>Type</th>
                  <th scope="col" className="text-end w-15">Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  (isLoading && (!isRefreshing))
                    ? (
                        Array.from(Array(10).keys()).map((n: number) => <SkeletonTableRow key={n} colSpan={5} actionQuantity={2} />)
                      )
                    : (
                        localProductCategories.length > 0
                          ? (localProductCategories.map((category: ProductCategory) => (
                            <tr
                              draggable="false"
                              key={category.id}
                              className={initialProductCategory.id === category.id ? 'table-primary' : ''}
                              onDragStart={(e) => {
                                e.dataTransfer.setData('text/plain', category.id)
                              }}
                              onDragOver={(e) => {
                                e.preventDefault()
                              }}
                              onDrop={(e) => {
                                e.preventDefault()
                                const draggedId = e.dataTransfer.getData('text')
                                const draggedIndex = localProductCategories.findIndex(cat => cat.id === draggedId)
                                const targetIndex = localProductCategories.findIndex(cat => cat.id === category.id)
                                if (draggedIndex !== targetIndex) {
                                  const newOrder = [...localProductCategories]
                                  const [reorderedItem] = newOrder.splice(draggedIndex, 1)
                                  newOrder.splice(targetIndex, 0, reorderedItem)

                                  // Update local state immediately
                                  setLocalProductCategories(newOrder)

                                  // Update server state
                                  if (token) {
                                    const controller = new AbortController()
                                    const signal = controller.signal
                                    const updatedCategories = newOrder.map((category, index) => ({
                                      productCategoryId: category.id,
                                      sortIndex: (page - 1) * perPage + index
                                    }))
                                    if (companyId) {
                                      dispatch(updateCompanyProductCategoriesSortOrder({ companyId, token, productCategories: updatedCategories, signal }))
                                    }
                                  }
                                }
                              }}
                            >
                              <td className="user-select-all" onClick={() => navigator?.clipboard.writeText(String(category.id))}>
                                {(category.id).substring(0, 8)}
                              </td>
                              <td>
                                {category.name}
                              </td>
                              <td className="text-center">
                                {category.isHidden ? <i className="bi bi-x-circle-fill text-danger"></i> : <i className="bi bi-check-circle-fill text-success"></i>}
                              </td>
                              <td>
                                {category.company ? <span className="fw-semibold">Custom</span> : <span className="fw-semibold">Default</span>}
                              </td>
                              <td className="text-end">
                                <div className="d-flex flex-row float-end" role="group" aria-label="Actions">
                                  <button
                                    className="btn btn-outline-dark btn-round me-2"
                                    type="button"
                                    title="Edit Product Category"
                                    data-bs-toggle="modal"
                                    data-bs-target="#productCategoryEditModal"
                                    onClick={() => {
                                      setInitialProductCategory(category)
                                      setIsProductCategoryEditMode(true)
                                    }}
                                    disabled={isLoadingProductCategory || (role !== userRoles.ADMIN && ((category.company === null) || !isAllowed(READWRITE)))}
                                  >
                                    <PencilIcon/>
                                  </button>
                                  <button
                                    className="btn btn-outline-danger btn-round"
                                    type="button"
                                    title="Delete Product Category"
                                    data-bs-toggle="modal"
                                    data-bs-target="#productCategoryConfirmationModal"
                                    onClick={() => {
                                      setInitialProductCategory(category)
                                    }}
                                    disabled={isLoadingProductCategory || (role !== userRoles.ADMIN && ((category.company === null) || !isAllowed(READWRITE)))}
                                  >
                                    <TrashIcon/>
                                  </button>
                                </div>
                              </td>
                            </tr>
                            ))
                            )
                          : (
                            <tr>
                              <td colSpan={6} className="text-center">
                                No product categories available yet
                              </td>
                            </tr>
                            )
                      )
                }
              </tbody>
            </table>
          </div>
          <Pagination
            isLoading={isLoading}
            metadata={{
              limit: metadata.perPage,
              total: metadata.total,
              offset: ((metadata.page - 1) * (metadata.perPage))
            }}
            page={page}
            perPage={perPage}
            handlePageChange={handlePageChange}
            handleShowEntries={handleShowEntries}
            isTrackingPage
          />
        </div>

        <div className="modal fade" id="cropImage" tabIndex={-1} aria-labelledby="cropImageLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="cropImageLabel">
                  <i className={'bi bi-crop me-1'}></i>{' '}
                  {'Crop Image'}
                </h5>
                <button type="button"
                  className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                  onClick={() => {
                    openModal('productCategoryModal')
                  }}
                >
                </button>
              </div>
              {isLoading && <Progress />}
              <div className="modal-body">
                <ImageCropper
                  width={300}
                  height={300}
                  image={imageBuffer || ''}
                  handleCrop={handleCroppedImage}
                />
                <div className='d-flex justify-content-end'>
                  <button
                    type="button"
                    className="btn btn-primary mt-2 "
                    onClick={() => {
                      if (croppedImageBuffer) {
                        handleFileUpload(croppedImageBuffer)
                      }
                    }}
                  >
                    Upload Cropped Image
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="productCategoryModal" tabIndex={-1} aria-labelledby="productCategoryModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="productCategoryModalLabel">
                  <i className={'bi bi-plus-circle me-1'}></i>{' '}
                  {'Add Product Category'}
                </h5>
                <button type="button"
                  onClick={() => dispatch(resetProductCategoryError())}
                  className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                >
                </button>
              </div>
              {isLoading && <Progress marginBottom={false} />}
              <div className="modal-body">
                <ProductCategoryEditor
                  id={companyId ?? ''}
                  initialProductCategory={initialProductCategory}
                  save={addCompanyProductCategory}
                  isEdit={false}
                  openCropModal={openCropModal}
                  uploadError={uploadError}
                  uploadProgress={uploadProgress}
                  image={image}
                  isImageUploading = {isImageUploading}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="productCategoryEditModal" tabIndex={-1} aria-labelledby="productCategoryEditModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="productCategoryEditModalLabel">
                  <i className={'bi bi-plus-circle me-1'}></i>{' '}
                  {'Edit Product Category'}
                </h5>
                <button type="button"
                  onClick={() => dispatch(resetProductCategoryError())}
                  className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                >
                </button>
              </div>
              {(isLoading || isLoadingProductCategory) && <Progress marginBottom={false} />}
              <div className="modal-body">
                <ProductCategoryEditor
                  id={initialProductCategory.id ?? ''}
                  initialProductCategory={initialProductCategory}
                  save={editProductCategoryById}
                  isEdit={true}
                  openCropModal = {openCropModal}
                  uploadError={uploadError}
                  uploadProgress={uploadProgress}
                  image = {image}
                  isImageUploading = {isImageUploading}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="productCategoryConfirmationModal" tabIndex={-1} aria-labelledby="productCategoryConfirmationModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h5 className="modal-title text-danger" id="productCategoryConfirmationModalLabel">
                  <i className="bi bi-trash text-danger me-2"></i>Confirm Delete
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              {isLoading && <Progress />}
              <div className="modal-body">
                <p>
                  Are you sure you want to delete the
                  <span className="fw-bold">{` '${(initialProductCategory.name)}' `}</span>
                  product category?
                </p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    if (token && initialProductCategory.id !== null) {
                      const controller = new AbortController()
                      const signal = controller.signal
                      dispatch(deleteProductCategoryById({ productCategoryId: String(initialProductCategory.id), token, signal }))
                    }
                  }}
                  disabled={isLoading}
                  data-bs-dismiss="modal"
                  aria-label="Delete"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default MyProductCategories
