import React, { useEffect, useRef, useState } from 'react'
import { ChartConfiguration } from 'chart.js'
import { Chart } from 'chart.js/auto'
import Select from 'react-select'
import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import SkeletonElement from '../loaders/skeleton/SkeletonElement'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setToast } from '../../store/reducers/toastReducer'
import { GetWeeklyBusinessMetrics, GetMonthlyBusinessMetrics, GetYearlyBusinessMetrics } from '../../services/api/graphQl/BusinessIntelligence/queries'
import { getAuthHeaders } from '../../utils/getAuthHeaders'

type Period = 'week' | 'month' | 'year'

type MetricData = {
  labels: string[]
  data: number[]
  percentageChange?: number
}

const NumberOf = {
  numberOfOrders: 'Orders',
  numberOfBillOfMaterialsShipped: 'Bundles',
  numberOfItemsShipped: 'Items',
  numberOfShipments: 'Shipments'
} as const

type MetricKey = keyof typeof NumberOf

interface BarGraphProps {
  color: string
  title: string
  metricKey: MetricKey
}

export default function BarGraph ({ title, color, metricKey }: BarGraphProps) {
  const chartRef = useRef<HTMLCanvasElement | null>(null)
  const chartInstance = useRef<Chart | null>(null)
  const dispatch = useAppDispatch()

  const currentUser = useAppSelector((state) => state.apiAuth.currentUser)
  const token = currentUser?.token || ''

  const [selectedPeriod, setSelectedPeriod] = useState<Period>('year')

  const timeOptions = [
    { value: 'week', label: 'Week' },
    { value: 'month', label: 'Month' },
    { value: 'year', label: 'Year' }
  ]

  const periodConfig = {
    week: {
      query: GetWeeklyBusinessMetrics,
      timeframe: 1,
      timeframeUnit: 'isoWeek',
      isStartOfTimeframeUnit: true,
      dataKey: 'daysOfWeek'
    },
    month: {
      query: GetMonthlyBusinessMetrics,
      timeframe: 1,
      timeframeUnit: 'month',
      isStartOfTimeframeUnit: true,
      dataKey: 'weeksOfMonth'
    },
    year: {
      query: GetYearlyBusinessMetrics,
      timeframe: 1,
      timeframeUnit: 'y',
      isStartOfTimeframeUnit: true,
      dataKey: 'months'
    }
  }

  const { query, timeframe, timeframeUnit, isStartOfTimeframeUnit, dataKey } = periodConfig[selectedPeriod] || periodConfig.year

  const { data, loading, error } = useQuery<{ businessIntelligenceOrderMetrics?: { orderMetrics?: Record<string, Record<string, MetricData>> } }>(query, {
    variables: { timeframe, timeframeUnit, isStartOfTimeframeUnit },
    context: getAuthHeaders(token)
  })

  const metricData = data?.businessIntelligenceOrderMetrics?.orderMetrics?.[metricKey]?.[dataKey] || { labels: [], data: [] }

  useEffect(() => {
    if (!chartRef.current || !metricData.labels || !metricData.data) return

    if (chartInstance.current) {
      chartInstance.current.destroy()
    }

    const ctx = chartRef.current.getContext('2d')
    if (!ctx) return

    const config: ChartConfiguration = {
      type: 'bar',
      data: {
        labels: metricData.labels,
        datasets: [{
          label: title,
          data: metricData.data,
          backgroundColor: color,
          borderWidth: 1,
          categoryPercentage: 1,
          barPercentage: 0.95
        }]
      },
      options: {
        responsive: true,
        scales: { y: { beginAtZero: true } },
        plugins: { legend: { display: false } }
      }
    }

    chartInstance.current = new Chart(ctx, config)

    return () => {
      chartInstance.current?.destroy()
    }
  }, [metricData, color, selectedPeriod])

  useEffect(() => {
    if (error?.message) {
      dispatch(setToast({
        title: 'Error',
        message: error.message,
        isVisible: true,
        timestamp: dayjs().format('LT'),
        type: 'danger'
      }))
    }
  }, [error])

  const totalOrders = metricData.data.reduce((acc, num) => acc + num, 0) || 0
  const percentageChange = metricData.percentageChange ?? 0

  return (
    <div className="container-fluid p-4 card" style={{ height: '100%' }}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <p className="text-dark fs-6 fw-bold mb-0">{title}</p>
        <Select
          className="w-auto small p-0"
          classNames={{
            option: () => 'text-nowrap'
          }}
          options={timeOptions}
          defaultValue={timeOptions[2]}
          isClearable={false}
          onChange={(option) => setSelectedPeriod((option?.value as Period) || 'month')}
        />
      </div>
      {loading
        ? (
        <>
          <div className="align-items-center mb-3">
            <SkeletonElement width='w-50' type='title' />
          </div>
          <div className="align-items-center mb-3 pb-1">
            <SkeletonElement width='w-50' type='text-small' />
          </div>
          <div className="skeleton-wrapper mt-2" style={{ height: '100%' }}>
            <SkeletonElement type="thumbnail" />
          </div>
        </>
          )
        : (
        <>
          <p className="m-0">
            <span className="fs-2 me-2" style={{ color }}>{totalOrders}</span>
            <span className={`fs-6 ${percentageChange < 0 ? 'text-primary' : 'text-success'}`}>
              {percentageChange !== 0 ? `${percentageChange >= 0 ? '+' : ''}${percentageChange.toFixed(2)}% vs. last ${selectedPeriod}` : '0% vs. last period'}
            </span>
          </p>
          <p className="text-black fs-6 m-0 py-2">
            {`${totalOrders} ${NumberOf[metricKey]} this ${selectedPeriod}`}
          </p>
          <div className="chart-container">
            <canvas ref={chartRef}></canvas>
          </div>
        </>
          )}
      <div className="d-flex align-items-center pe-1 py-1 mt-2">
        <div className="rounded-circle" style={{ backgroundColor: color, width: '16px', height: '16px' }}></div>
        <p className="mb-0 ms-2">{title}</p>
      </div>
    </div>
  )
}
