import React from 'react'
import BarGraph from '../../components/Charts/BarGraph'

export default function Dashboard () {
  return (
    <div className="container-fluid p-4 overflow-x-hidden">
      <div className="card p-4">
        <div className="py-1 align-items-center">
          <div className="pb-1">
            <div className="navbar navbar-expand justify-content-between align-items-center">
              <p className="m-0 py-2">
                <i className="bi bi-bar-chart-line fs-1 me-2"></i>
                <span className="text-dark fs-2 fw-bold">Business Intelligence Application</span>
                <span className="text-dark fs-6 fw-normal"> - Your Efficiency at a Glance</span>
              </p>
            </div>
          </div>
          <hr className="border border-primary border-1 opacity-50" />
        </div>
        <div className="row g-4">
          <div className="col-12 col-md-6 col-lg-3">
            <BarGraph color="red" title="Number of Orders" metricKey="numberOfOrders" />
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <BarGraph color="black" title="Number of Bundles Shipped" metricKey="numberOfBillOfMaterialsShipped" />
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <BarGraph color="red" title="Number of Items Shipped" metricKey="numberOfItemsShipped" />
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <BarGraph color="black" title="Number of Shipments" metricKey="numberOfShipments" />
          </div>
        </div>
      </div>
    </div>
  )
}
