import { gql } from '@apollo/client'

export const GetWeeklyBusinessMetrics = gql`
  query GetWeeklyBusinessMetrics($timeframe: Int, $timeframeUnit: TimeframeUnit, $isStartOfTimeframeUnit: Boolean) {
    businessIntelligenceOrderMetrics(timeframe: $timeframe, timeframeUnit: $timeframeUnit, isStartOfTimeframeUnit: $isStartOfTimeframeUnit) {
      statusCode
      success
      orderMetrics {
        numberOfCitiesServiced
        totalNumberOfOrders
        totalNumberOfItemsShipped
        totalNumberOfBillOfMaterialsShipped
        totalNumberOfShipments
        numberOfBillOfMaterialsShipped{
          daysOfWeek {
            labels
            data
            percentageChange
          }
        }
        numberOfShipments {
          daysOfWeek {
            labels
            data
            percentageChange
          }
        }
        numberOfOrders {
          daysOfWeek {
            labels
            data
            percentageChange
          }
        }
        numberOfItemsShipped {
          daysOfWeek {
            labels
            data
            percentageChange
          }
        }
      }
    }
  }
`

export const GetMonthlyBusinessMetrics = gql`
  query GetMonthlyBusinessMetrics($timeframe: Int, $timeframeUnit: TimeframeUnit, $isStartOfTimeframeUnit: Boolean) {
    businessIntelligenceOrderMetrics(timeframe: $timeframe, timeframeUnit: $timeframeUnit, isStartOfTimeframeUnit: $isStartOfTimeframeUnit) {
      statusCode
      success
      orderMetrics {
        numberOfCitiesServiced
        totalNumberOfOrders
        totalNumberOfItemsShipped
        totalNumberOfBillOfMaterialsShipped
        totalNumberOfShipments
        numberOfBillOfMaterialsShipped {
          weeksOfMonth {
            labels
            data
            percentageChange
          }
        }
        numberOfShipments {
          weeksOfMonth {
            labels
            data
            percentageChange
          }
        }
        numberOfOrders {
          weeksOfMonth {
            labels
            data
            percentageChange
          }
        }
        numberOfItemsShipped {
          weeksOfMonth {
            labels
            data
            percentageChange
          }
        }
      }
    }
  }
`

export const GetYearlyBusinessMetrics = gql`
  query GetYearlyBusinessMetrics($timeframe: Int, $timeframeUnit: TimeframeUnit, $isStartOfTimeframeUnit: Boolean) {
    businessIntelligenceOrderMetrics(timeframe: $timeframe, timeframeUnit: $timeframeUnit, isStartOfTimeframeUnit: $isStartOfTimeframeUnit) {
      statusCode
      success
      orderMetrics {
        numberOfCitiesServiced
        totalNumberOfOrders
        totalNumberOfItemsShipped
        totalNumberOfBillOfMaterialsShipped
        totalNumberOfShipments
        numberOfBillOfMaterialsShipped {
          months {
            labels
            data
            percentageChange
          }
        }
        numberOfShipments {
          months {
            labels
            data
            percentageChange
          }
        }
        numberOfOrders {
          months {
            labels
            data
            percentageChange
          }
        }
        numberOfItemsShipped {
          months {
            labels
            data
            percentageChange
          }
        }
      }
    }
  }
`

export const GetCountriesServicedCount = gql`
  query GetCountriesServicedCount($timeframe: Int, $timeframeUnit: TimeframeUnit, $isStartOfTimeframeUnit: Boolean) {
    businessIntelligenceOrderMetrics(timeframe: $timeframe, timeframeUnit: $timeframeUnit, isStartOfTimeframeUnit: $isStartOfTimeframeUnit) {
      statusCode
      success
      orderMetrics {
        totalNumberOfCountriesServiced
        numberOfCountriesServiced {
          country
          count
        }
      }
    }
  }
`
